// QuestionPage.js
import React, { useState, useEffect } from "react";
import CreateQuestion from "../../components/createTest/TestQuestion";
import SquarePagination from "../../components/pagination/squarePagination";
import { HiPlus } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { Buttons } from "../../components/button/button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { returnErrors } from "../../data/Reducers/ErrorReducer";
import axios from "axios";
import AddButton from "../../components/button/addbutton";

function hasUniqueValue(array, propertyName, desiredValue) {
	let count = 0;

	for (let i = 0; i < array.length; i++) {
		if (array[i][propertyName]) {
			count++;
			if (count > 1) {
				return false; // More than one object has the desired value
			}
		}
	}

	return count === 1; // Only one object has the desired value
}

const QuestionPage = () => {
	let init = { question: "", answer: "", questionType: "text" },
		[stateData2, setStateData2] = useState({
			deliverySeconds: "",
			hours: "",
			minutes: "",
		}),
		textChange2 =
			name =>
			({ target: { value } }) => {
				setStateData2({ ...stateData2, [name]: value });
			};
	const [questions, setQuestions] = useState([init]);

	let location = useLocation(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false),
		dispatch = useDispatch();

	useEffect(() => {
		if (!location?.state) navigate(-1);
	}, [location?.state, navigate]);

	const handleInputChangeForMutipleItem = (event, index, field, option) => {
		const { value } = event.target;
		let itemValue = field === "answer" ? option : value;

		// if (field === "price") {
		// 	let parsedValue = parseFloat(itemValue);
		// 	if (Number.isInteger(parsedValue)) {
		// 		parsedValue = parsedValue.toFixed(0);
		// 		itemValue = parsedValue;
		// 	}
		// }

		setQuestions(prevRows => {
			const newRows = [...prevRows];
			newRows[index][field] = itemValue;
			return newRows;
		});
	};

	const handleDeleteRowForMutipleItem = index => {
		setQuestions(prevRows => {
			const newRows = [...prevRows];
			newRows.splice(index, 1);
			return newRows;
		});
	};

	let handleChangeImage =
		(index, field, options = "") =>
		e => {
			const file = e.target.files[0];
			let err = "";

			if (!file) return (err = `File, ${file?.name} does not exist`);
			if (!file.type.includes("image"))
				return (err = `File, ${file?.name} format not supported`);

			if (err) {
				return toast.error(err);
			} else {
				// setLogo(file);

				setQuestions(prevRows => {
					const newRows = [...prevRows];
					newRows[index][field] = file;
					newRows[index][`${options ? field : ""}questionType`] = "file";
					return newRows;
				});
			}
		};

	let handleChangeImageRemoved =
		(index, field, options = "") =>
		() => {
			setQuestions(prevRows => {
				const newRows = [...prevRows];
				newRows[index][field] = null;
				newRows[index][`${options ? field : ""}questionType`] = "text";
				return newRows;
			});
		};

	const addRowForMutipleItem = () => {
		const newRow = init;
		setQuestions([...questions, newRow]);
	};
	// console.log({ questions });
	let handleSubmit = async () => {
		let errArr = [],
			returnedData = [];
		if (questions?.length === 0) return toast.info("Questions required");

		for (let e = 0; e < questions.length; e++) {
			let element = questions?.[e],
				newOption = [];
			element.options = [];
			element?.options?.push({
				option: element?.option_a || "",
				isCorrect: element?.answer === "option_a",
				file: element?.option_afile,
				optionType: element?.option_afilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_b || "",
				isCorrect: element?.answer === "option_b",
				file: element?.option_bfile,
				optionType: element?.option_bfilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_c || "",
				isCorrect: element?.answer === "option_c",
				file: element?.option_cfile,
				optionType: element?.option_cfilequestionType || "text",
			});
			element?.options?.push({
				option: element?.option_d || "",
				isCorrect: element?.answer === "option_d",
				file: element?.option_dfile,
				optionType: element?.option_dfilequestionType || "text",
			});
			if (element?.option_e)
				element?.options?.push({
					option: element?.option_e || "",
					isCorrect: element?.answer === "option_e",
					file: element?.option_efile,
					optionType: element?.option_efilequestionType || "text",
				});
			if (!element?.question)
				errArr.push({
					message: `Question ${e + 1} is required`,
					path: "question",
				});
			if (!element?.options)
				errArr.push({
					message: `Question ${e + 1} options is required`,
					path: "options",
				});
			if (element?.questionType)
				if (element?.questionType !== "text") {
					if (!element?.file)
						errArr.push({
							message: `Question ${e + 1} file is required`,
							path: "file",
						});
				}

			if (!hasUniqueValue(element?.options, "isCorrect", true)) {
				errArr.push({
					message: `Question ${e + 1} options requires a single answer`,
					path: "isCorrect",
				});
			}
			// console.log({ element });
			for (let o = 0; o < element?.options.length; o++) {
				let elementOption = element?.options?.[o];
				if (!elementOption?.option && !elementOption?.file)
					errArr.push({
						message: `Question ${e + 1} Option ${o + 1} is required`,
						path: "option",
					});
				if (elementOption?.optionType)
					if (elementOption?.optionType !== "text") {
						if (!elementOption?.file)
							errArr.push({
								message: `Question ${e + 1} Option ${o + 1} file is required`,
								path: "file",
							});
					}
				newOption?.push({ ...elementOption });
			}
			returnedData?.push({ ...element, options: newOption });
		}
		// console.log({ returnedData });
		if (errArr?.length > 0) return dispatch(returnErrors({ error: errArr }));
		setLoading(true);
		let newReturnedData = [];
		for (let r = 0; r < returnedData.length; r++) {
			let element = returnedData[r],
				newOption = [];

			if (element?.file) {
				try {
					let post = new FormData();
					post.append(`intendedFile`, element?.file);

					let resImg = await axios.post(`/api/v1/file`, post, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					});

					element.file = resImg?.data?.data?.[0];
				} catch (err) {
					if (err) console.log({ error: err.response?.data, err });
					if (err?.response?.status === 429) toast.error(err?.response?.data);

					let error = err.response?.data?.error;
					if (error) {
						setLoading(false);
						return dispatch(
							returnErrors({ error, status: err?.response?.status })
						);
					} else {
						setLoading(false);
						return toast.error(err?.response?.data?.message);
					}
				}
			}
			for (let o = 0; o < element?.options.length; o++) {
				let elementOption = element?.options?.[o];
				if (elementOption?.file) {
					try {
						let post = new FormData();
						post.append(`intendedFile`, elementOption?.file);

						let resImg = await axios.post(`/api/v1/file`, post, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						});

						elementOption.file = resImg?.data?.data?.[0];
					} catch (err) {
						if (err) console.log({ error: err.response?.data, err });
						if (err?.response?.status === 429) toast.error(err?.response?.data);

						let error = err.response?.data?.error;
						if (error) {
							setLoading(false);
							return dispatch(
								returnErrors({ error, status: err?.response?.status })
							);
						} else {
							setLoading(false);
							return toast.error(err?.response?.data?.message);
						}
					}
				}

				newOption?.push({ ...elementOption });
			}
			element.options = newOption;
			newReturnedData?.push(element);
		}
		let newTime;

		let newMinutes = 0,
			newHours = 0;
		if (stateData2?.hours) {
			newHours = Number(stateData2?.hours) * 60;
		}
		if (stateData2?.minutes) {
			newMinutes = Number(stateData2?.minutes);
		}
		newTime = Number(Number(newMinutes) + Number(newHours));
		// console.log({ newTime, newMinutes, newHours });
		try {
			let dd = {
				...location?.state,
				questions: newReturnedData,
				// duration: newTime,
			};
			if (newTime > 0) dd.duration = newTime;
			if (location?.state?._id) {
				dd.exam = location?.state?._id;
			}
			let res = await axios.post(
				`/api/v1/exam${
					location?.state?._id ? `?exam=${location?.state?._id}` : ""
				}`,
				dd
			);
			console.log({ resp: res?.data });
			toast.success(res?.data?.message);
			navigate("/manage-exams/question", { state: location?.state });
		} catch (err) {
			if (err?.response?.status === 429 || err?.response?.status === 405)
				toast.error(err?.response?.data ? err?.response?.data : err?.message);
			console.log({ err });
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				dispatch(returnErrors({ error, status: err?.response?.status }));
			} else {
				toast.error(err?.response?.data?.message);
			}
		}
		setLoading(false);
		setLoading(false);
	};

	// console.log({ questions });

	return (
		<div className="">
			<AddButton
				onClick={() => navigate("/manage-exams")}
				title={"Back"}
				type="back"
			/>
			<div className="flex">
				<div className="p-4 w-full">
					{questions?.map((q, i) => (
						<CreateQuestion
							key={i}
							question={q}
							handleDeleteRowForMutipleItem={handleDeleteRowForMutipleItem}
							handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
							index={i}
							questionsLength={questions?.length}
							handleChangeImage={handleChangeImage}
							handleChangeImageRemoved={handleChangeImageRemoved}
						/>
					))}
					<Buttons
						onClick={handleSubmit}
						loading={loading}
						title={"Submit"}
						width={
							"my-4 px-4 py-2 bg-[#FD5709] rounded-full text-white w-20 h-12 flex justify-center items-center text-lg ms-auto"
						}
					/>
				</div>
				<div className="h-screen-full w-96 bg-white">
					{/* {!location?.state?._id && ( */}
					<div>
						<label htmlFor="Exam schedule">Exam duration</label>

						<div className="grid grid-col-3">
							<div className="mb-3">
								<label htmlFor="Hours">Hours</label>
								<input
									type="number"
									required
									name="Exam hours"
									className="w-full p-2 mt-2 border border-gray-300 rounded shadow"
									placeholder="Hours"
									value={stateData2?.hours}
									onChange={textChange2("hours")}
								/>
							</div>
							<div className="mb-3">
								<label htmlFor="Minutes">Minutes</label>
								<input
									type="number"
									required
									name="Exam minutes"
									className="w-full p-2 mt-2 border border-gray-300 rounded shadow"
									placeholder="Minutes"
									value={stateData2?.minutes}
									onChange={textChange2("minutes")}
								/>
							</div>
						</div>
					</div>
					{/* )} */}
					<div className="grid grid-col-5">
						<SquarePagination totalQuestions={questions?.length} />
						<button
							onClick={addRowForMutipleItem}
							className="mb-4 px-4 py-2 bg-[#FD5709] rounded-full text-white w-12 h-12 flex justify-center items-center text-lg">
							<HiPlus className="icon font-bold" size={24} />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuestionPage;
