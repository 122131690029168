import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Add jquery.
import $ from "jquery";
// Load wiris formula render script.
const jsDemoImagesTransform = document.createElement("script");
jsDemoImagesTransform.type = "text/javascript";
jsDemoImagesTransform.src =
	"https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
document.head.appendChild(jsDemoImagesTransform);
// This needs to be included before the '@wiris/mathtype-tinymce6' is loaded synchronously
window.$ = $;
window.tinymce = require("tinymce"); // Expose the TinyMCE to the window.
// Load wiris plugin synchronously.
require("@wiris/mathtype-tinymce6");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
