import React, { useRef } from "react";
import { HiTrash } from "react-icons/hi";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import { Editor } from "@tinymce/tinymce-react";

const CreateQuestion = ({
	question,
	handleInputChangeForMutipleItem,
	index,
	handleDeleteRowForMutipleItem,
	questionsLength,
	handleChangeImage,
	handleChangeImageRemoved,
}) => {
	let fileInputRef = useRef();

	return (
		<div className="mb-4 p-4 border rounded-lg">
			<label className="font-bold">Question {index + 1}:</label>
			{/* <h1 className='text-[]'>{question.question}</h1> */}
			<input
				type="text"
				className="w-full p-2 mt-2 border border-gray-300 rounded shadow hidden"
				// onChange={handleInputField}
				value={question?.question}
				onChange={event =>
					handleInputChangeForMutipleItem(event, index, "question")
				}
			/>
			<ReactQuillEditor
				value={question?.question}
				setValue={ev => {
					let e = {
						target: {
							value: ev,
						},
					};
					handleInputChangeForMutipleItem(e, index, "question");
				}}
			/>
			<div className="flex flex-col items-center gap-4 mt-8">
				{question?.file && (
					<img
						src={question?.file ? URL.createObjectURL(question?.file) : null}
						alt=""
						className="h-48 w-auto rounded-sm border"
					/>
				)}
				<div className="flex gap-6 ms-auto">
					{question?.file && (
						<button
							type="button"
							onClick={handleChangeImageRemoved(index, "file")}
							className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal">
							Remove
						</button>
					)}
					<div>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleChangeImage(index, "file")}
							name="image"
						/>
						<button
							type="button"
							className="h-8 w-36 bg-main rounded-xl text-white barlow text-base font-normal"
							onClick={() => fileInputRef.current.click()}>
							Upload Image
						</button>
					</div>
				</div>
			</div>
			<div className="mt-2">
				{/* Option A  */}
				<OptionCreate
					option={"option_a"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="A"
				/>

				{/* Option B  */}
				<OptionCreate
					option={"option_b"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="B"
				/>

				{/* Option C  */}
				<OptionCreate
					option={"option_c"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="C"
				/>

				{/* Option D  */}
				<OptionCreate
					option={"option_d"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="D"
				/>
				{/* Option E  */}
				<OptionCreate
					option={"option_e"}
					question={question}
					index={index}
					handleInputChangeForMutipleItem={handleInputChangeForMutipleItem}
					handleChangeImage={handleChangeImage}
					handleChangeImageRemoved={handleChangeImageRemoved}
					title="E"
				/>
			</div>
			{questionsLength > 1 && (
				<button
					onClick={() => handleDeleteRowForMutipleItem(index)}
					className="mb-4 px-4 py-2 bg-[#FD5709] rounded-full text-white w-12 h-12 flex justify-center items-center text-lg ms-auto">
					<HiTrash className="icon font-bold" size={24} />
				</button>
			)}
		</div>
	);
};

export default CreateQuestion;

export const OptionCreate = ({
	question,
	index,
	handleInputChangeForMutipleItem,
	handleChangeImage,
	handleChangeImageRemoved,
	option,
	title,
}) => {
	let fileInputRef = useRef();

	return (
		<>
			<div className="w-full p-2 mt-2 border border-gray-600 rounded flex items-center gap-x-4">
				<span className="text-lg font-semibold">{title}</span>
				<input
					type="text"
					className="w-full p-2 border-0 border-gray-300 rounded outline-none focus:outline-none focus:shadow-none shadow hidden"
					value={question?.[option]}
					onChange={event =>
						handleInputChangeForMutipleItem(event, index, option)
					}
				/>
				<ReactQuillEditor
					value={question?.[option]}
					setValue={ev => {
						let e = {
							target: {
								value: ev,
							},
						};
						handleInputChangeForMutipleItem(e, index, option);
					}}
				/>
				<input
					type="radio"
					name={`answer${index}`}
					className="mr-3 shadow"
					value={question?.answer}
					checked={question?.answer === option}
					onChange={event =>
						handleInputChangeForMutipleItem(event, index, "answer", option)
					}
				/>
			</div>
			<div className="flex flex-col items-center gap-4 mt-8">
				{question?.[`${option}file`] && (
					<img
						src={
							question?.[`${option}file`]
								? URL.createObjectURL(question?.[`${option}file`])
								: null
						}
						alt=""
						className="h-48 w-auto rounded-sm border"
					/>
				)}
				<div className="flex gap-6 ms-auto">
					{question?.[`${option}file`] && (
						<button
							type="button"
							onClick={handleChangeImageRemoved(index, `${option}file`, "file")}
							className="h-8 w-24 bg-main rounded-xl text-white barlow text-base font-normal">
							Remove
						</button>
					)}
					<div>
						<input
							ref={fileInputRef}
							type="file"
							accept="image/*"
							style={{ display: "none" }}
							onChange={handleChangeImage(index, `${option}file`, "file")}
							name="image"
						/>
						<button
							type="button"
							className="h-8 w-36 bg-main rounded-xl text-white barlow text-base font-normal"
							onClick={() => fileInputRef.current.click()}>
							Upload Image
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

// const modules = {
// 	toolbar: {
// 		container: [
// 			[{ header: [1, 2, false] }],
// 			["bold", "italic", "underline", "strike"],
// 			[{ list: "ordered" }, { list: "bullet" }],
// 			[{ script: "sub" }, { script: "super" }],
// 			["mathtype", "chemistry"], // Custom MathType and Chemistry buttons
// 			["clean"],
// 		],
// 		handlers: {
// 			mathtype: () => {
// 				if (window.Wiris) {
// 					window.Wiris.MathtypeIntegration.openMathTypeEditor();
// 				}
// 			},
// 			chemistry: () => {
// 				if (window.Wiris) {
// 					window.Wiris.MathtypeIntegration.openChemistryEditor();
// 				}
// 			},
// 		},
// 	},
// };

export const ReactQuillEditor = ({ value, setValue }) => {
	// const quillRef = useRef(null);

	return (
		<>
			{/* <ReactQuill
				value={value}
				onChange={e => setValue(e)}
				modules={modules}
				ref={quillRef}
				theme="snow"
				placeholder="Write something..."
			/> */}

			<Editor
				// apiKey={
				// 	process.env.REACT_APP_TINY_API_KEY ||
				// 	"qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
				// }
				licenseKey="gpl"
				value={value}
				init={{
					height: 200,
					menubar: false,
					// Add wiris plugin
					external_plugins: {
						tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
					},
					// external_plugins: {
					// 	tiny_mce_wiris:
					// 		"https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
					// },
					plugins: [
						"advlist autolink lists link image charmap print preview anchor",
						"searchreplace visualblocks code fullscreen",
						"insertdatetime media table paste imagetools code help wordcount",
					],
					toolbar: `undo redo | formatselect | bold italic underline | \
               alignleft aligncenter alignright alignjustify | \
               tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry |\
               bullist numlist | removeformat`,
					htmlAllowedTags: [".*"],
					htmlAllowedAttrs: [".*"],
					extended_valid_elements: "*[.*]",
				}}
				onEditorChange={e => setValue(e)}
			/>
		</>
	);
};
