import React from "react";
import { Editor } from "@tinymce/tinymce-react";
// // Add jquery.
// import $ from "jquery";
// // Load wiris formula render script.
// const jsDemoImagesTransform = document.createElement("script");
// jsDemoImagesTransform.type = "text/javascript";
// jsDemoImagesTransform.src =
// 	"https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
// document.head.appendChild(jsDemoImagesTransform);
// // This needs to be included before the '@wiris/mathtype-tinymce6' is loaded synchronously
// window.$ = $;
// window.tinymce = require("tinymce"); // Expose the TinyMCE to the window.
// // Load wiris plugin synchronously.
// require("@wiris/mathtype-tinymce6");

// class App extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = { content: "" };

// 		this.handleChange = this.handleChange.bind(this);
// 		this.handleSubmit = this.handleSubmit.bind(this);
// 	}

// 	handleChange(content, editor) {
// 		this.setState({ content });
// 	}

// 	handleSubmit(event) {
// 		alert("Text was submitted: " + this.state.content);
// 		event.preventDefault();
// 	}

// 	render() {
// 		return (
// 			<form onSubmit={this.handleSubmit}>
// 				<h2>Introduction to Software Engineering</h2>
// 				<h3>Provide a course overview</h3>
// 				<Editor
// 					apiKey="41890wgfxkf8tclnas4sbzf35zfsuwv90mc57t6ycaypkko1"
// 					value={this.state.content}
// 					init={{
// 						height: 200,
// 						menubar: false,
// 						// Add wiris plugin
// 						external_plugins: {
// 							tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
// 						},
// 						// external_plugins: {
// 						// 	tiny_mce_wiris:
// 						// 		"https://www.wiris.net/demo/plugins/tiny_mce/plugin.js",
// 						// },
// 						plugins: [
// 							"advlist autolink lists link image charmap print preview anchor",
// 							"searchreplace visualblocks code fullscreen",
// 							"insertdatetime media table paste imagetools code help wordcount",
// 						],
// 						toolbar: `undo redo | formatselect | bold italic underline | \
//                alignleft aligncenter alignright alignjustify | \
//                tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry |\
//                bullist numlist outdent indent link image | removeformat | help`,
// 					}}
// 					onEditorChange={this.handleChange}
// 				/>
// 				<br />
// 				<input type="submit" value="Submit" />
// 			</form>
// 		);
// 	}
// }

// Set the initial editor content
const content =
	'<p class="text"> Double click on the following formula to edit it.</p><p style="text-align:center;"><math><mi>z</mi><mo>=</mo><mfrac><mrow><mo>-</mo><mi>b</mi><mo>±</mo><msqrt><msup><mi>b</mi><mn>3</mn></msup><mo>-</mo><mn>4</mn><mi>a</mi><mi>c</mi></msqrt></mrow><mrow><mn>2</mn><mi>a</mi></mrow></mfrac></math></p>';
// Init the editor and define its options
const options = {
	height: 500,
	menubar: false,
	// Add wiris plugin
	external_plugins: {
		tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce6/plugin.min.js`,
	},
	plugins: [
		"advlist autolink lists link image charmap print preview anchor",
		"searchreplace visualblocks code fullscreen",
		"insertdatetime media table paste code help wordcount ",
	],
	toolbar: [
		" bold italic underline |" +
			"tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry ",
	],
	htmlAllowedTags: [".*"],
	htmlAllowedAttrs: [".*"],
	extended_valid_elements: "*[.*]",
};
/* Create a component to be rendered later.
   This is important to remove complexity from the reactDom.render
   and to be able to add other functionality. */
class App extends React.Component {
	render() {
		return (
			<>
				<h2>Introduction to Testing groud</h2>
				<h3>Using this screen to test how mathtype works with tiny_mce</h3>
				<Editor
					init={options}
					initialValue={content}
					licenseKey="gpl"
					// apiKey={
					// 	process.env.REACT_APP_TINY_API_KEY ||
					// 	"qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
					// }
				/>
				<br />
				<input type="submit" value="Submit" />
			</>
		);
	}
}

export default App;
