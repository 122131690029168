import React, { useState } from "react";
import { RiFileEditLine } from "react-icons/ri";
import { AiOutlineDelete } from "react-icons/ai";
import Delete from "../modal/delete";
import DOMPurify from "dompurify";

export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const Question = ({
	question,
	to,
	item,
	deleteFunc,
	index,
	setOptionsEdit,
	setUpdater,
}) => {
	const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

	const openModalDelete = () => {
		setIsModalOpenDelete(true);
	};

	const closeModalDelete = () => {
		setIsModalOpenDelete(false);
	};

	return (
		<div>
			<div className="flex justify-between items-center p-4">
				<div>
					<p className={`my-4 font-semibold`}>
						Q{!setUpdater ? index + 1 : null}:
						<span dangerouslySetInnerHTML={createMarkup(question)} />
						{/* {question} */}
					</p>
					{item?.file && (
						<img
							src={item?.file?.url || null}
							alt=""
							className="h-48 w-auto rounded-sm border"
						/>
					)}
				</div>
				<div className="flex items-center gap-x-4">
					<span
						onClick={
							setOptionsEdit
								? () => setOptionsEdit(item)
								: setUpdater
								? () => setUpdater({ item, type: "question" })
								: null
						}>
						<RiFileEditLine
							className="icon text-[#0966AB] cursor-pointer"
							size={16}
						/>
					</span>
					<AiOutlineDelete
						className="icon text-[#E60000] cursor-pointer"
						size={16}
						onClick={openModalDelete}
					/>
				</div>

				<Delete
					isOpenDelete={isModalOpenDelete}
					closeModalDelete={closeModalDelete}
					datum={item}
					type="Question"
					deleteFunc={() => deleteFunc(item, closeModalDelete)}
				/>
			</div>
		</div>
	);
};

export default Question;

export const QuestionsOption = ({
	question,
	to,
	item,
	deleteFunc,
	index,
	setOptionsEdit,
	mainQuestion,
	setUpdater,
}) => {
	const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);

	const openModalDelete = () => {
		setIsModalOpenDelete(true);
	};

	const closeModalDelete = () => {
		setIsModalOpenDelete(false);
	};
	// console.log({ item });
	return (
		<div>
			<div className="flex justify-between items-center p-4">
				<div>
					<p
						className={`my-4 font-semibold ${
							item?.isCorrect ? "text-[#1b933b]" : ""
						}`}>
						Option{index + 1}:
						<span dangerouslySetInnerHTML={createMarkup(question)} />
						{/* {question} */}
					</p>
					{item?.file && (
						<img
							src={item?.file?.url || null}
							alt=""
							className="h-48 w-auto rounded-sm border"
						/>
					)}
				</div>
				<div className="flex items-center gap-x-4">
					<span
						onClick={
							setOptionsEdit
								? () => setOptionsEdit(item)
								: setUpdater
								? () => setUpdater({ item, type: "option" })
								: null
						}>
						<RiFileEditLine
							className="icon text-[#0966AB] cursor-pointer"
							size={16}
						/>
					</span>
					<AiOutlineDelete
						className="icon text-[#E60000] cursor-pointer"
						size={16}
						onClick={openModalDelete}
					/>
				</div>

				<Delete
					isOpenDelete={isModalOpenDelete}
					closeModalDelete={closeModalDelete}
					datum={item}
					type="Option"
					deleteFunc={() => deleteFunc(item, closeModalDelete, "option")}
				/>
			</div>
		</div>
	);
};
